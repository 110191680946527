.signin {
  background-color: #f9fbff;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
}

nav {
  width: 100%;
  height: 48px;
  background-color: #0d1623;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

nav > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

nav > div > p {
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #fff;
  margin: 0;
}

.login-form {
  width: 345px;
  position: relative;
  left: 50%;
  top: 190px;
  transform: translateX(-50%);
  text-align: center;
}

.login-form > h1 {
  width: 100%;
  font-family: PingFangTC;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #0d1623;
}

.login-form > h4 {
  width: 100%;
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #3f5372;
  margin-bottom: 30px;
  text-align: center;
}

.input {
  height: 40px;
}

.ant-input-password > .ant-input::placeholder {
  color: #6D7B94;
  position: relative;
  top: 1px;
}

.ant-form-item-explain-error {
  margin: 6px 0 16px;
}

.login-btn {
  background-color: #2a7b7c !important;
  width: 100%;
  height: 40px !important;
  border: 1px solid #2a7b7c !important;
}
