@import '~antd/dist/antd.css';

.app-section-logout {
  margin-left: 0;
  width: 100vw;
  height: calc(100vh);
}

.app-section {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  width: 100vw;
  /* height: calc(100vh); */
}
