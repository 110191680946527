.ant-table-filter-dropdown {
  padding: 16px 20px;
  width: 320px;
  height: 128px;
}

.ant-table-filter-dropdown {
  padding: 16px 20px !important;
}

.ant-table-filter-dropdown > div >.ant-input {
  width: 100%;
  height: 40px;
  margin-bottom: 16px !important;
}

.search-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
