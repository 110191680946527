.sitebar-content-title {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 18px !important;
}

.sitebar-content-title > h1 {
  font-family: PingFangTC;
  font-size: 24px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #0d1623 !important;
}

.ant-tabs {
  width: 100%;
}

.add-question-title {
  padding-bottom: 19px;
  border-bottom: 1px solid #e6ebf5;
}

.add-question-upload {
  height: auto;
  align-items: flex-start !important;
}

.add-question-time-group
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.add-question-upload
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > span {
  position: relative;
  top: 0;
}

.add-question-upload
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > span {
    max-width: 500px;
}

.ant-upload-list-text {
  display: flex;
  flex-direction: column;
  padding: 1px 0;
  max-height: 500px;
}

.ant-upload-list-item-name {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d76c6 !important;
}

.add-question-btn-group {
  display: flex;
  flex-direction: column;
}

.add-question-btn-group > span {
  margin-top: 12px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5372 !important;
}

.add-question-editor {
  width: 100%;
  max-width: 100%;
  padding: 24px !important;
  border: solid 1px #e6ebf5;
  display: flex;
  flex-direction: column;
}

.add-question-editor-drop {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #e6ebf5;
  height: 100px;
}

.editor-new-btn {
  font-family: PingFangTC;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94;
  cursor: pointer;
}

.editor-menu {
  width: 170px;
  font-family: PingFangTC;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
  position: relative;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.editor-drag-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  width: 100%;
  height: 40px;
  background-color: #e6ebf5;
}

.ant-tabs-nav::before {
  border: 0 !important;
}

.ant-form-item-explain-error {
  margin-bottom: 0px !important;
}

form > .product-open-form {
  margin-bottom: 0 !important;
}
