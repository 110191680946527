.customer-search-input {
  height: 40px !important;
}

.customer-search-input-loading {
  height: 40px !important;
}

.customer-search-input > .ant-input-wrapper > .ant-input {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94 !important;
  height: 40px !important;
}
.customer-search-input > .ant-input-wrapper > .ant-input::placeholder {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94 !important;
  position: relative;
}

.ant-input-affix-wrapper-disabled {
  background-color: #e6ebf5 !important;
}

.ant-input-search-button {
  height: 40px !important;
  background-color: #254a91 !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff !important;
  border: 0;
}

.search-btn-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 30px);
  height: 100%;
  background: #000;
  margin-left: -15px;
  border: 1px solid #000;
}

.note-text {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5372 !important;
}

.customer-search-input-loading
  > .ant-input-wrapper
  > .ant-input-group-addon
  > .ant-input-search-button {
  width: 96px !important;
  height: 40px !important;
  background-color: #daa360 !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff !important;
  border: 0;
}

.customer-search-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ant-input-affix-wrapper {
  height: 40px;
}

.ant-input-affix-wrapper > .ant-input {
  height: 37px;
  position: relative;
  top: -6px;
}
