.question-preview-modal > .ant-modal-content > .ant-modal-header {
  border-bottom: 0 !important;
  padding: 32px 31px 16px 31px;
}

.question-preview-modal
> .ant-modal-content
> .ant-modal-header
> .ant-modal-title {
  border-bottom: 0 !important;
  font-size: 24px !important;
  font-family: PingFangTC;
  font-stretch: normal !important;
  font-weight: 600 !important;
  font-style: normal !important;
  line-height: 1.42 !important;
  letter-spacing: -0.3px !important;
  color: #0d1623 !important;
}

.question-preview-category {
  font-family: PingFangTC;
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5372;
}

.question-preview-category > span {
  display: inline-block;
  margin-left: 16px;
  color: #daa360;
}

.question-preview-category > span:last-child {
  display: inline-block;
  margin-left: 16px;
  color: #3f5372;
}

.question-preview-modal
> .ant-modal-content
> .ant-modal-body
> .ant-tabs
> .ant-tabs-nav {
  padding: 0 33px !important;
  margin: 0;
}

.question-preview-modal
> .ant-modal-content
> .ant-modal-body
> .ant-tabs
> .ant-tabs-nav
> .ant-tabs-nav-wrap
> .ant-tabs-nav-list
> .ant-tabs-tab
> .ant-tabs-tab-btn {
  font-size: 16px;
}

.question-preview-modal
> .ant-modal-content
> .ant-modal-body
> .ant-tabs
> .ant-tabs-nav {
  border: 1px solid #f0f0f0;
}

.question-preview-modal
> .ant-modal-content
> .ant-modal-body
> .ant-tabs
> .ant-tabs-content-holder
> .ant-tabs-content
> .ant-tabs-tabpane
> article {
  padding: 24px 0px;
}

.question-preview-modal > .ant-modal-content > .ant-modal-body > hr {
  margin: 15px 0;
  background-color: #e6ebf5;
  border-bottom: 0;
}

.toggle-section {
  margin-bottom: 10px;
}

.preview-download-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  padding: 8px;
  background-color: #f2f2f2;
}

.ant-pagination-item-active {
  background-color: #2a7b7c !important;
  border-color: #2a7b7c !important;
}

.ant-pagination-item-active > a {
  color: #fff !important;
}

.question-preview-modal > .ant-modal-content > .ant-modal-body {
  padding: 0 !important;
  padding-right: 0 !important;
}

.preview-modal-article > * > span > img {
  /* margin: 0 auto; */
  display: flex;
  width: auto;
  max-width: 696px;
  height: auto;
}

.preview-modal-article > p > img {
  /* margin: 0 auto; */
  display: flex;
  width: auto;
  height: auto;
  max-width: 696px;
}

.preview-modal-article > h1 > img {
  /* margin: 0 auto; */
  display: flex;
  width: auto;
  height: auto;
  max-width: 696px;
}

.preview-modal-article > h2 > span > img {
  /* margin: 0 auto; */
  display: flex;
  width: auto;
  height: auto;
  max-width: 696px;
}

.preview-modal-newPan {
  padding: 0 33px !important;
}

.preview-modal-article > h1 {
  font-size: 20px;
}

.preview-modal-article > h2 {
  font-size: 16px;
}
