.question-preview-modal > .ant-modal-content > .ant-modal-header {
  border-bottom: 0 !important;
  padding: 32px 31px 16px 31px;
}

.question-preview-modal
> .ant-modal-content
> .ant-modal-header
> .ant-modal-title {
  border-bottom: 0 !important;
  font-family: PingFangTC;
  font-size: 24px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.42 !important;
  letter-spacing: -0.3px !important;
  color: #0d1623 !important;
}

.question-preview-category {
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5372;
  padding: 0 33px;
}

.ant-modal-body > .question-modal-body {
  padding: 0 33px !important;
}


.question-preview-category > span {
  color: #daa360;
  margin-left: 16px;
  display: inline-block;
}

.question-preview-category > span:last-child {
  color: #3f5372;
  margin-left: 16px;
  display: inline-block;
}

.question-preview-modal > .ant-modal-content > .ant-modal-body {
  width: auto;
  height: 85vh;
  padding: 0 !important;
  overflow-y: auto;
}

.question-preview-modal > .ant-modal-content > .ant-modal-body > hr {
  background-color: #e6ebf5;
  border-bottom: 0;
  margin: 15px 0;
}

.ant-modal-body .ant-tabs {
  min-height: 100%;
}

.ant-modal-content h1 {
  font-weight: 600 !important;
  font-size: 20px !important;
}

.ant-modal-content h1::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 10px 12px 3px 0;
  background-color: #2a7b7c;
}

.ant-modal-content h2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-modal-content a,
.ant-modal-content a:hover{
  color: #daa360;
}

.ant-modal-footer {
  /* display: none; */
}

.toggle-section {
  margin-bottom: 10px;
}

.preview-download-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #f2f2f2;
}

/* pagination */

.ant-pagination-options .ant-select {
  height: auto !important;
}
