.sider-container {
  position: fixed;
  z-index: 999;
  max-height: 100vh;
}

.sider-menu-group {
  height: calc(100vh - 48px) !important;
}

.siderbar-header {
  width: 100%;
  height: 80px;
  background-color: #3f5372;
  color: white;
  display: flex;
  /* justify-content: center; */
  padding: 0 22px;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(63, 83, 114, 0) 95%,
    #24354f 99%
  );
}

.siderbar-header > img {
  width: 150px;
}

.ant-menu-item-selected {
  background-color: #daa360 !important;
}

.ant-menu-dark {
  background: #0d1623 !important;
}

.ant-menu-sub {
  background: #192639 !important;
}

.icon-sub-menu > .ant-menu-submenu-title > .ant-menu-title-content {
  margin-left: 8px !important;
}

.ant-menu-title-content {
  margin-left: 8px !important;
}