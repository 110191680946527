.customer-input {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94;
  margin: 0 !important;
}

.customer-input::placeholder {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94 !important;
  margin: 0 !important;
}

.ant-input[disabled] {
  border: solid 1px #e6ebf5 !important;
  background-color: #e6ebf5 !important;
}

.customer-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.validateMsg {
  margin-top: 10px;
}