.menu-flex-form-label {
  width: 100% !important;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu-flex-form-label > .ant-space-item {
  /* height: 40px !important; */
  width: 100% !important;
  height: auto;
}

.small-label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  height: 100%;
  font-size: 14px;
}

.ant-drawer-header {
  border: 0 !important;
}

.ant-drawer-title {
  font-family: PingFangTC;
  font-weight: normal;
  font-size: 24px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 48px !important;
  letter-spacing: -0.3px;
  color: #0d1623 !important;
  border-bottom: 1px solid #f0f0f0;
}

.ant-drawer-close {
  display: none !important;
}

.ant-drawer-body {
  padding-top: 3px !important;
  padding-bottom: 24px !important;
  position: relative !important;
}

form > .ant-form-item {
  margin-bottom: 16px !important;
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-form-item-label > label {
  height: 40px !important;
  font-weight: normal;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
}

.form-input-with-checkbox {
  margin-bottom: 16px !important;
}

.form-input-with-checkbox > .ant-form-item-control {
  margin-left: 6.6% !important;
}

.trust-input-item > .ant-form-item-control {
  margin-left: 6.8% !important;
}

.trust-input-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  flex-direction: column;
}

.trust-input-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content > .ant-space {
  margin-bottom: 8px;
}

.trust-input-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content > .ant-space:last-child {
  margin-bottom: 0px;
}

.isOpen-form {
  height: 40px !important;
}

.ant-form-item-label > label::after {
  content: '' !important;
}

.ant-form-item-label > .ant-form-item-required::after {
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;
  content: '*' !important;
}

.draw-form-button-group {
  position: absolute !important;
  bottom: 0;
  width: 93%;
}

.form-input-with-checkbox > .ant-form-item-control {
  width: 100% !important;
}

.draw-form-button-group
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
}

.draw-form-button-group
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > hr {
  margin-bottom: 19px;
  width: 100%;
  border: 1px solid #e6ebf5;
}

.draw-form-button-group
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-space {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100%;
}

.ant-drawer-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-form-item-control-input-content {
  display: flex !important;
  align-items: center !important;
}

.ant-form-item-control-input-content > span {
  font-weight: normal;
  font-family: PingFangTC;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
}

.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-form-item {
  margin: 0;
}

.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-space
  > .ant-space-item
  > .ant-form-item {
  margin: 0;
}

.form-sub-title {
  margin-bottom: 8px !important;
}

.form-sub-menu {
  position: relative;
  left: 162px;
  margin-bottom: 16px !important;
  width: 489px !important;
}

.drawer-validator-message {
  margin-top: -12px;
  margin-bottom: 16px;
  margin-left: 8.5rem;
  color: rgb(255, 77, 79);
}

.data-table-img {
  width: 80px;
  height: 50px;
}

form > .form-has-two-input {
  margin-bottom: 0 !important;
}

form > .form-has-two-input-checked {
  margin-bottom: 0 !important;
}

form
  > .form-has-two-input
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: grid !important;
  grid-template-columns: 1fr;
}

.drawer-form-timepicker {
  width: 100% !important;
}

.ant-form
  > .trust-form
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: initial !important;
}

.ant-form > .trust-form {
  margin-bottom: 0 !important;
}

.ant-form > .trust-from-checkbox {
  margin-bottom: 0 !important;
  height: 40px !important;
}

.ant-form
  > .trust-from-checkbox
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-checkbox-wrapper {
  margin: 0 !important;
}

.ant-form > .menu-form-with-checkbox {
  /* height: 40px !important; */
  margin-bottom: 0 !important;
}

.ant-form > .menu-form-with-checkbox:nth-child(2) {
  /* height: 40px !important; */
  margin-bottom: 16px !important;
}

.ant-form > .menu-form-with-checkbox > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-checkbox-wrapper {
  margin: 0 !important;
}

/* tree */

.drawer-tree-section {
  display: flex;
  align-items: flex-start !important;
  height: 500px !important;
}

.drawer-tree-section > .ant-form-item-control {
  height: 500px !important;
}

.drawer-tree-section > .ant-form-item-control > .ant-form-item-control-input {
  align-items: flex-start !important;
  height: 500px !important;
}

.drawer-tree-section
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  align-items: flex-start !important;
  height: 500px !important;
}

.ant-tree {
  position: relative;
  top: 8px;
}

.customer-search-input + .validateMsg {
  position: relative;
  top: 4px;
}

.validateMsg {
  color: #ff4d4f;
}

.ant-form-item-required::before {
  display: none !important;
}
