.ant-table-thead > tr > th {
  background: #e6ebf5 !important;
}

.ant-table-tbody {
  background-color: #fff !important;
}

.status-valid {
  color: '#22a16f';
}

.table-link {
  color: '#0d1623';
  transition-duration: 1;
}

.table-link:hover {
  border-bottom: 1px solid #0d1623;
}

.dataTable-tag {
  display: inline-block;
  padding: 2px 9px 3px;
  border-radius: 2px;
  border-radius: 2px;
  border: solid 1px #e6ebf5;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
  margin-right: 8px;
}

.dataTable-tooltip {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  cursor: initial;
}

.ant-table-filter-column,
.ant-table-column-sorters {
  justify-content: flex-start !important;
}

.ant-table-filter-dropdown .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 0;
  padding-left: 5px;
}

.ant-table-filter-dropdown {
  width: 230px;
}

.ant-table-filter-dropdown-btns .ant-btn {
  width: 80px;
  height: 40px;
}

.ant-table-filter-dropdown-btns span {
  font-size: 16px;
}

.ant-table-filter-dropdown-btns .ant-btn:first-of-type span {
  color: #0d1623;
}

.ant-table-filter-dropdown-btns .ant-btn.ant-btn-link.ant-btn-sm {
  border: solid 1px #A9B6CB;
  border-radius: 2px;
}

.ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary.ant-btn-sm {
  background:#254A91;
  border-color: #254A91;
  border-radius: 2px;
}

.ant-table-filter-dropdown .ant-checkbox-inner {
  border: 1px solid #E6EBF5;
  background-color: #fff !important;
  border-radius: 4px !important;
}

.ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #254A91 !important;
  border-radius: 4px !important;
}

.ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #0d1623 !important;
  background-color: transparent !important;
}

.ant-table-filter-trigger-container,
.ant-table-filter-trigger,
.ant-table-column-sorter {
  position: relative !important;
}

.ant-table-filter-column-title,
.ant-table-column-title {
  flex: unset !important;
  padding-right: 0em !important;
}

.dataTable-expand-button {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataTable-expand-button > img {
  width: 14px;
  height: 14px;
}

/* .ant-table-column-title {
  width: auto !important;
} */

.drop-over-downward > td {
  border-bottom: 2px dashed #1890ff !important;
}

.drop-over-upward > td {
  border-top: 2px dashed #1890ff;
}

.table-feature-icon {
  margin-right: 30%;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #daa360;
  cursor: pointer;
}

.ant-table-cell {
  padding: 12px 16px !important;
  color: #3f5372 !important;
}

/* .ant-table-filter-column > .ant-table-filter-trigger {
  position: relative;
  left: -45px;
} */

.ant-table-cell-ellipsis {
  white-space: initial !important;
}
