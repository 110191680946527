.ant-checkbox-wrapper {
  margin: 13px 0 !important;
}

.ant-checkbox {
  width: 21px !important;
  height: 21px !important;
  border-radius: 6px !important;
}

.ant-checkbox-checked::after {
  border-radius: 6px !important;
  border-color: #e6ebf5 !important;
}

.ant-checkbox-input {
  width: 21px !important;
  height: 21px !important;
  border-radius: 6px !important;
}

.ant-checkbox-inner {
  width: 21px !important;
  height: 21px !important;
  border-radius: 6px !important;
  background-color: #e6ebf5 !important;
  border-color: #e6ebf5 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 5px;
  border: 3px solid #6c7b94 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
}

.ant-checkbox-wrapper > span {
  font-family: PingFangTC;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94 !important;
}
