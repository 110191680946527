.ant-tabs-tab {
  /* background-color: #e6ebf5 !important; */
  font-family: PingFangTC;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
}

.ant-tabs-nav-add {
  background-color: #e6ebf5 !important;
}

.ant-tabs-nav-add > .anticon {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a9b6cb !important;
}

.ant-tabs-nav-add > .anticon::after {
  content: '加入選單';
  margin-left: 6px;
  position: relative;
  top: -2px;
}

.draggable-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  background-color: #e6ebf5 !important;
}

.draggable-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active {
  background-color: #fff !important;
}
