.ant-picker {
  height: 40px !important;
  /* width: 45% !important; */
  position: relative;
  top: 3px;
}

.ant-picker-disabled {
  background-color: #e6ebf5 !important;
}

.ant-picker-input > input {
  font-family: PingFangTC;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #a9b6cb !important;
}

.ant-picker-input:hover > .ant-picker-suffix {
  color: rgba(0, 0, 0, 0);
}

.ant-picker-suffix {
  width: 20px !important;
  height: 20px !important;
}

.ant-picker-suffix > .anticon {
  width: 20px !important;
  height: 20px !important;
  font-size: 18px;
}

.ant-picker-range > .ant-picker-input > input::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #6c7b94;
}

.ant-picker-range > .ant-picker-input > input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
}

.ant-picker-focused:hover > .ant-picker-suffix {
  color: rgba(0, 0, 0, 0);
}

.ant-picker-focused:focus > .ant-picker-suffix {
  color: rgba(0, 0, 0, 0);
}

