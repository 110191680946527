.upload-area {
  display: flex;
  align-items: center;
}

.upload-area > p {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: #3f5372;
}

/* .create-product-upload .ant-upload-list-text-container {
  display: none;
} */
