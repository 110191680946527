.four-o-four-group {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  margin-top: 30%;
  width: 100%;
  height: 100%;
  /* transform: translateY(-50%); */
}

.four-o-four-404 {
  font-family: PingFangTC;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #0d1623;
  border-right: 1px solid #e6ebf5;
  padding: 0 20px;
  margin: 0;
}

.four-o-four-description {
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #0d1623;
  border-right: 1px solid #e6ebf5;
  padding: 0 20px;
  margin: 0;
  align-self: center;
}