.customer-button {
  padding: 8px 17px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: -1.1px !important;
  height: initial !important;
  border: 0px !important;
}

.customer-button-red {
  background-color: #2a7b7c !important;
  color: #fff !important;
  border: 0px solid #2a7b7c !important;
}

.customer-button-red:focus {
  border: 0px solid #2a7b7c !important;
}

.customer-button-red:hover {
  border: 0px solid #2a7b7c !important;
}


.customer-button-blue {
  background-color: #254a91 !important;
  color: #fff !important;
  border: 0px solid #254a91;
}

.customer-button-blue:focus {
  border: 0px solid #254a91 !important;
}

.customer-button-blue:hover {
  border: 0px solid #254a91 !important;
}
