.sub-table-modal > .ant-modal-content > .ant-modal-body {
  width: auto;
  padding: 15px 20px !important;
}

.ant-modal-title {
  font-family: PingFangTC;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
}

.function-modal-content {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
  margin-bottom: 12px;
}

.function-modal-input {
  height: 40px;
  margin-top: 12px !important;
}

.ant-modal-footer {
  padding-bottom: 16px;
}

.sub-table {
  width: 100% !important;
}

.sub-table-tbody > tr > td:first-child {
  border: solid 1px #e6ebf5 !important;
  width: 150px;
  height: 40px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
  background-color: #f8f9fc;
}

.sub-table-tbody > tr > td:last-child {
  border: solid 1px #e6ebf5 !important;
  width: 330px;
  height: 40px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
}
