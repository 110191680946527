.ant-table-thead > tr > th {
  background: #e6ebf5 !important;
  border-bottom: 1px solid #a9b6cb !important;
  border-top: 1px solid #a9b6cb !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0 !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0 !important;
}

.ant-table-expanded-row > .ant-table-cell {
  padding: 0 !important;
}

.ant-table-pagination > li {
  height: 40px !important;
}

.ant-table-pagination > .ant-pagination-item {
  padding-top: 3px !important;
}