.formal-url {
  display: flex;
  align-items: center;
  height: 40px;
}

.url-search {
  margin-top: 15px;
}

.ant-input-group-addon {
  padding-bottom: 16px !important;
}

.anticon-eye {
  display: none !important;
}

.image-form-item {
  margin: 20px 0 !important;
}
