.ant-select {
  min-height: 40px;
}

.ant-select-selector {
  min-height: inherit !important;
}

.ant-select-selection-search .ant-select-selection-search-input {
  min-height: inherit !important;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center !important;
  font-family: PingFangTC;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94 !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center !important;
  font-family: PingFangTC;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c7b94 !important;
}
