.sitebar-container {
  z-index: 99 !important;
}

.sitebar-header {
  position: fixed;
  z-index: 99 !important;
  width: calc(100vw - 200px);
  height: 50px;
  color: #fff;
  background-color: #3f5372;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sitebar-header > p {
  margin: 0;
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sitebar-header-user {
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 48px;
  justify-content: space-around;
}

.sitebar-user-avatar {
  margin: 0;
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sitebar-user-name {
  margin: 0;
  font-family: PingFangTC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sitebar-content-section {
  padding: 15px 24px 24px 24px;
}

.sitebar-content {
  padding: 24px;
  background-color: #fff;
  min-height: 85vh;
}

.ant-tabs-tab {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #0d1623;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0d1623 !important;
}

.ant-tabs-ink-bar {
  background-color: #daa360;
  height: 5px !important;
}

.sitebar-content-title-platform {
  justify-content: flex-start !important;
}

.sitebar-content-title-platform > h1 {
  margin: 0 22px;
}
