.ant-switch-container {
  padding: 5px 0;
}

.ant-switch-handle::before {
  background-color: #a9b6cb !important;
  border-radius: 999px !important;
}

.ant-switch {
  background-color: #e6ebf5 !important;
  width: 55px !important;
  height: 30px !important;
}
.ant-switch-handle {
  top: 3px !important;
  left: 4px !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 10px) !important;
}

.ant-switch-handle {
  width: 23px !important;
  height: 23px !important;
}

.ant-switch-checked {
  background-color: #F0F2F5 !important;
}

.ant-switch-checked > .ant-switch-handle::before {
  background-color: #2a7b7c !important;
}

.ant-switch + span {
  position: relative;
  top: 2px;
}
