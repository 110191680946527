.ant-modal-title {
  font-family: PingFangTC;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
}

.cancel-modal-content {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
  margin: 0;
}

.ant-modal-footer {
  padding-bottom: 16px;
}

.ant-modal-close {
  display: none;
}
