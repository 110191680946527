.editor-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 230px;
  border: 1px solid #e6ebf5;
  margin-bottom: 20px;
}

.editor-header {
  position: absolute;
  top: -40px;
  margin: 8px 16px;
  background-color: #e6ebf5;
}

.editor-toggle-container {
  height: initial;
}

/* .editor-header > span {
  margin-right: 30px;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
  cursor: pointer;
} */

.link-icon {
  transform: rotate(45deg);
  position: relative;
  top: 1px;
}

.public-DraftEditor-content {
  padding: 12px 16px;
}

.RichEditor-styleButton {
  margin-right: 30px;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
  cursor: pointer;
}

.RichEditor-activeButton {
  color: #5890ff !important;
}

.editor-body {
  padding: 30px;
}

.editor-body .customer-input-container .customer-input {
  border: 1px solid#E6EBF5;
}

.editor-body .ant-input {
  margin-bottom: 0 !important;
  padding: 8px 10px;
  font-size: 1rem;
  color:#3F5372;
}

.editor-body .ant-input .customer-input {
  border: 1px solid#E6EBF5;
  padding: 8px 10px;
  color: #3F5372 !important;
  font-size: 1rem;
}

.editor-title-input {
  margin-bottom: 12px !important;
}
