.ant-radio-wrapper {
  display: flex;
  align-items: center !important;
  height: inherit !important;
}

.ant-radio-group {
  height: inherit !important;
  display: flex !important;
  align-items: center !important;
}

.ant-radio {
  width: 28px !important;
  height: 28px !important;
}

.ant-radio-checked::after {
  border: 0 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  transform: scale(.5) !important;
}

.ant-radio-input {
  width: 28px !important;
  height: 28px !important;
}

.ant-radio-inner {
  width: 28px !important;
  height: 28px !important;
  border: 1px solid #e6ebf5 !important;
}

.ant-radio-inner::after {
  width: 32px !important;
  height: 32px !important;
  top: 5px !important;
  left: 5px !important;
  margin-top: -8px !important;
  margin-left: -8px !important;
  border-radius: 16px !important;
  background-color: #2a7b7c !important;
}

.ant-radio-wrapper > span:last-child {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623 !important;
  position: relative;
  top: 3px;
}
