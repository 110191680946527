.ant-modal-title {
  font-family: PingFangTC;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: hsl(215, 46%, 9%);
}

.function-modal-content {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d1623;
  margin-bottom: 10px !important;
  padding-bottom: 10px !important;
}

.function-modal > .ant-modal-content > .ant-modal-body {
  padding-bottom: 27px;
}

.function-modal
  > .ant-modal-content
  > .ant-modal-body
  > .modal-input-required::after {
  content: '*';
  color: #ff4d4f;
  margin-left: 2px;
}

.function-modal-input {
  margin-top: 12px !important;
  margin-bottom: 8px !important;
}

.ant-modal-footer {
  padding-bottom: 16px;
}
